<template>
  <div class="package-card text-center flex flex-col justify-between max-w-xs sm:max-w-md">
    <div>
      <img :src="img" class="product-img" />
      <div class="lora-bold mt-4">{{ title }}</div>
      <div class="mt-2 px-2">{{ subtitle }}</div>
      <div class="mt-4 lora-bold text-size-16">
        {{ format(price) }} {{ content.iqd }}
      </div>
    </div>
    <div @click="$emit('updateSelected', value)">
      <div class="relative" v-if="selectedItem == value">
        <div class="select-btn mb-2 lora-bold bg-blueSecondary p-3 mx-2">
          {{ content.selected }}
        </div>
        <div
          class="radio-fake-check ms-4"
          :class="{ 'selected-radio': selectedItem == value }"
        >
          <i class="fas fa-check"></i>
        </div>
      </div>
      <div class="select-btn mb-2 lora-bold bg-blueSecondary p-3 mx-2" v-else>
        {{ content.select }}
      </div>
    </div>
  </div>
</template> 

<script>
import { format } from "/src/assets/numberFormat";
export default {
  emits: ["updateSelected"],
  props: ["title", "subtitle", "img", "price", "value", "selectedItem"],
  inject: ["content"],
  methods: {
    format(price) {
      return format(price);
    },
  },
};
</script>