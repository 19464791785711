<template>
  <div class="layout-padding my-12">
    <div class="grid grid-cols-2 gap-x-20 md:gap-x-30">
      <div class="col-span-2 lg:col-span-1">
        <div
          class="gift-subscription-img mb-6 lg:mb-0"
          :style="`background: url(${text?.image})`"
        ></div>
      </div>
      <div class="col-span-2 lg:col-span-1 flex flex-col justify-center">
        <div class="page-title uppercase lora-bold">
          {{ content.theCandleSubscription }}
        </div>
        <div class="breadcrumb mt-3">
          <router-link to="/">{{ content.home }}</router-link> /
          <router-link to="/workshops"> {{ content.shop }}</router-link> /
          {{ content.theCandleSubscription }}
        </div>
        <div class="mt-12 line-height-28">
          {{ text?.description }}
        </div>
        <button
          class="header-btn hover:text-bluePrimary hover:bg-blueSecondary lora-bold whitespace-nowrap bg-bluePrimary text-white duration-300 w-max-content mt-8"
          @click="scrollDown"
        >
          {{ content.getStarted }}
        </button>
      </div>
    </div>
    <hr style="color: #f2715b22" class="my-8" id="gift-text-section" />
    <div class="page-title lora-bold text-secondaryColor">
      {{ content.getStarted }}
    </div>
    <div class="gift-subscription-stepper mt-12">
      <div class="flex flex-col">
        <div class="step-1 flex items-center">
          <div class="number sm:grid hidden" :class="{ 'active-step': currentStep == 1 }">
            1
          </div>
          <div class="ms-4">
            <div class="lora-bold">{{ content.step1 }}</div>
            <div class="subtitle">
              {{ content.isThisGift }}
            </div>
          </div>
        </div>
        <div class="step-content flex">
          <div><div class="line hidden smd:flex"></div></div>
          <div class="ms-0 md:ms-4">
            <div class="flex flex-wrap py-4 mt-5">
              <SimpleRadioButton
                :text="content.itsForMe"
                :value="0"
                :selectedItem="gift"
                @updateValue="updateGift"
              />
              <SimpleRadioButton
                :text="content.thisIsGift"
                :value="1"
                :selectedItem="gift"
                @updateValue="updateGift"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="step-2 flex items-center">
          <div
            class="number hidden smd:grid"
            :class="{ 'active-step': currentStep == 2 }"
          >
            2
          </div>
          <div class="ms-4">
            <div class="lora-bold">{{ content.step2 }}</div>
            <div class="subtitle">
              {{ content.whichPackageWillItBe }}
            </div>
          </div>
        </div>
        <div class="step-content flex">
          <div><div class="line hidden smd:flex"></div></div>
          <div class="ms-0 md:ms-4">
            <div class="mt-5">
              <div
                v-if="currentStep >= 2"
                class="flex flex-wrap py-4 gift-step"
                id="step-two"
                :class="{ 'gift-active-step-container': currentStep >= 2 }"
              >
                <PackageCard
                  v-for="(item, index) in packages"
                  :key="item.id"
                  class="me-0 md:me-12 mb-10"
                  :title="item?.title"
                  :subtitle="item?.description"
                  :price="item?.prices[0]?.price"
                  @updateSelected="updatePackage"
                  :selectedItem="packageIndex"
                  :value="index"
                  :img="item?.image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="step-3 flex items-center">
          <div
            class="number hidden smd:grid"
            :class="{ 'active-step': currentStep == 3 }"
          >
            3
          </div>
          <div class="ms-4">
            <div class="lora-bold">{{ content.step3 }}</div>
            <div class="subtitle">
              {{ content.howWouldYouLikeToPay }}
            </div>
          </div>
        </div>
        <div class="step-content flex">
          <div><div class="line last-line hidden smd:flex"></div></div>
          <div class="ms-0 md:ms-4">
            <div class="mt-5">
              <div
                v-if="currentStep >= 3"
                class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 gift-step py-4"
                id="step-three"
                :class="{ 'gift-active-step-container-3': currentStep >= 3 }"
              >
                <DiscountRadioButton
                  v-for="(item, index) in packages[packageIndex].prices"
                  :key="item.id"
                  :title="months(item.months)"
                  :subtitle="`${item.price * item.months} ${content.iqd}`"
                  :selectedItem="price"
                  :discount="discount(index)"
                  :value="item.id"
                  @setPayment="setPrice"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center" v-if="price">
      <router-link
        class="header-btn text-bluePrimary hover:text-white hover:bg-bluePrimary lora-bold whitespace-nowrap bg-blueSecondary duration-300 me-4"
        :to="{
          name: 'subscriptionCheckout',
          query: {
            p: packageId,
            m: price,
            g: gift,
          },
        }"
      >
        {{ content.goToCheckout }}
      </router-link>
    </div>
  </div>
</template>

<script>
import SimpleRadioButton from "/src/components/ui/Buttons/SimpleRadioButton.vue";
import PackageCard from "/src/components/ui/PackageCard.vue";
import DiscountRadioButton from "/src/components/ui/Buttons/DiscountRadioButton.vue";

export default {
  components: {
    SimpleRadioButton,
    PackageCard,
    DiscountRadioButton,
  },
  inject: ["content"],
  data() {
    return {
      loading: true,
      gift: null,
      packageIndex: null,
      packageId: null,
      price: null,
      currentStep: 1,
    };
  },
  async mounted() {
    await this.$store.dispatch("subscription/packages");
    this.loading = false;
  },
  computed: {
    text() {
      return this.$store.getters["pages/text"]("subscription");
    },
    packages() {
      return this.$store.getters["subscription/packages"];
    },
  },
  methods: {
    updateGift(value) {
      this.gift = value;
      this.currentStep = 2;
      setTimeout(() => {
        document.getElementById("step-two").scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    scrollDown() {
      document.getElementById("gift-text-section").scrollIntoView({ behavior: "smooth" });
    },
    updatePackage(value) {
      this.packageIndex = value;
      this.packageId = this.packages[value].id;
      this.currentStep = 3;
      setTimeout(() => {
        document.getElementById("step-three").scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    setPrice(value) {
      this.price = value;
    },
    months(months) {
      return months == 1 ? this.content.monthly : `${months} ${this.content.month_s}`;
    },
    discount(index) {
      let perMonth = this.packages[this.packageIndex].prices[0];

      // console.log(this.packages[this.packageIndex].prices);

      if (this.packages[this.packageIndex].prices[index].id == perMonth.id) {
        return 0;
      }

      let discount = parseFloat(
        100 -
          (this.packages[this.packageIndex].prices[index].price / perMonth.price) * 100
      );

      return Intl.NumberFormat("en", { maximumSignificantDigits: 2 }).format(discount);
    },
    // setPaymentMethod(value) {
    //   this.selectedPayment = value;
    // },
  },
};
</script>
