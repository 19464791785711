<template>
  <div
    class="flex border-with-radius pe-4 ps-4 py-2 cursor-pointer col-span-1"
    :class="{ 'selected-container': selectedItem == value }"
    @click="$emit('setPayment', value)"
  >
    <div
      class="radio-gift-fake-check me-4"
      :class="{ 'selected-radio': selectedItem == value }"
    >
      <i class="fas fa-check"></i>
    </div>
    <div
      class="text-start"
      :class="{ 'text-bluePrimary': selectedItem == value }"
    >
      <div>{{ title }}</div>
      <div class="mt-2 lora-bold flex justify-between w-full">
        <div class="me-2">
          {{ subtitle }}
        </div>
        <div v-if="discount > 0">
          ({{ content.save }} {{ format(discount) }}%)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";
export default {
  inject: ["content"],
  emits: ["setPayment"],
  props: ["title", "subtitle", "value", "selectedItem", "discount"],
  methods: {
    format(number) {
      return format(number);
    },
  },
};
</script>